<template>
  <p>
    <slot></slot>
  </p>
</template>

<style lang="scss" scoped>
p {
  color: var(--text-color);
}
</style>
